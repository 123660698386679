import React from "react";
import WhatWeDoCard from "./WhatWeDoCard";
import data from "../assets/data.png";
import cloud from "../assets/cloud.png";
import cyber from "../assets/cyber.png";
import dev from "../assets/dev.png";
import networking from "../assets/networking.png";
import consultancy from "../assets/Consultancy.png";

const WhatWeDoCardsGroup = () => {
  return (
    <section>
      <div className="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
        <div className="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-3">
          <WhatWeDoCard
            heading2="Data Center"
            para="
            The data centers are designed for high-density configurations, supporting the latest in server and storage technology. Security is paramount. Our facilities implement multi-layered security protocols including biometric access controls, 24/7 surveillance, and on-site security personnel. The data centers are designed for high-density configurations, supporting the latest in server and storage technology. Connectivity is the backbone of our data center services. Our data centers comply with rigorous standards and regulations, including ISO 27001, SOC 1, SOC 2, and PCI DSS. Experience 24/7/365 expert support from our dedicated team of IT professionals..
                "
            image={data}
          />

          <WhatWeDoCard
            heading2="Cloud Services

            "
            para="We offer high flexibility and scalability, allowing users to quickly scale resources up or down based on demand. Also Offering a development and deployment environment in the cloud, including tools to deliver everything from simple cloud-based apps to sophisticated cloud-enabled enterprise applications. Delivering software applications over the internet, on a subscription basis. Cloud storage services provide scalable, secure, and cost-efficient solutions to store data. These services often include options for object storage, block storage, and file storage, and are designed for high durability, availability, and scalability..
                "
            image={cloud}
          />
          <WhatWeDoCard
            heading2="Cyber Security"
            para="Cybersecurity is a multifaceted discipline crucial in safeguarding digital assets from an array of threats such as malware, phishing, and ransomware. With regard to the rapid advancement of technology in many fields, it is critical to comprehend the foundations of cybersecurity. Essential ideas like as threat detection, vulnerability control, risk evaluation, and user awareness serve as the cornerstone of successful cybersecurity plans. Forging stronger digital defences requires putting in place reliable procedures like data encryption, strong authentication procedures, and frequent software upgrades. Further essential elements of preserving cyber resilience include cultivating a security-aware culture and proactive monitoring for questionable activity.

           .
               "
            image={cyber}
          />
          <WhatWeDoCard
            heading2="Web & Software Development

            "
            para="Web and software development are integral processes for creating digital solutions tailored to organizational needs. Creating dynamic and aesthetically pleasing websites and online apps requires web development, which includes database integration, front-end design, and back-end programming to guarantee device responsiveness and speed. On the other hand, software development is centred on developing desktop, mobile, or business programmes that follow a disciplined process from requirements collecting to deployment and maintenance, with the goal of automating chores or solving particular issues. Working together, communicating clearly, and following best practices are essential for both disciplines to produce results that stimulate creativity and productivity in the current digital era.

            .
                "
            image={dev}
          />
          <WhatWeDoCard
            heading2="Networking"
            para="Our networking services focus on the design and implementation of high-performance, scalable, and resilient network infrastructures. We deliver VoIP and Unified Communications services, providing high-quality voice and video communication solutions over the network. Our services are designed for scalability, integrating with existing infrastructure and supporting a range of communication tools and platforms. We offer expert consulting and support services, providing guidance on network design, capacity planning, and best practices. Our network designs are made to fit your present demands and provide you the ability to scale up as your business grows, whether you're a tiny startup or a huge organization.
                "
            image={networking}
          />
          <WhatWeDoCard
            heading2="IT Consultancy

          "
            para="Providing guidance on the strategic planning of IT to align technology initiatives with business goals. IT consultants assess the cybersecurity posture of an organization, identify vulnerabilities, and recommend measures to mitigate risks. This includes implementing cybersecurity frameworks, conducting risk assessments, and developing disaster recovery and business continuity plans. Also offering project management services to oversee the planning, execution, and completion of IT projects. This includes defining project scope, managing resources, ensuring timely delivery, and aligning projects with business goals.

          .
              "
            image={consultancy}
          />
        </div>
      </div>
    </section>
  );
};

export default WhatWeDoCardsGroup;
