import React from "react";
import { Twitter, Linkedin } from "../assets/svgs/ServiceSvgs";

const Footer = () => {
  return (
    <div>
      <footer className="relative bg-blueGray-200 pt-20 pb-6 ">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-left lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-display font-bold text-font-primary-color">
                Let's keep in touch!
              </h4>
              <h5 className="text-base mt-0 mb-2 text-font-secondary-color font-display font-semibold">
                Find us on any of these platforms, we respond 1-2 business days.
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-display font-normal h-10 w-10 items-center justify-center align-center  outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a
                    href="https://x.com/vishagtech?s=11"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter />
                  </a>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-display font-normal h-10 w-10 items-center justify-center align-center outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <a
                    href="https://www.linkedin.com/company/vishag-tech-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Linkedin />
                  </a>
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4"></div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-800 font-display font-semibold py-1">
                Copyright © <span id="get-current-year">2024</span>
                <a
                  href="https://www.creative-tim.com/product/notus-js"
                  className="text-gray-800 hover:text-gray-800"
                  target="_blank"
                >
                  {" "}
                  VISHAG TECH PVT LTD
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
