import React, { useState } from "react";
import FrontPageHero from "./FrontPageHero";
import WhatWeDoCard from "./WhatWeDoCard";
import cloudImage from "../assets/servicesImage/cloud.jpg";
import cyberImage from "../assets/servicesImage/cyber.jpg";
// import dataImage from "../assets/servicesImage/data.jpg";
import devImage from "../assets/servicesImage/dev.jpg";

const dataCenterContent = `
Gone are the days of bulky servers and overheating rooms. Modern data centres are advanced, slick, and essential to almost everything in the digital world. So grab a seat, and join us as we explore the newest and best in data centre services.

The Evolution of Data Centers in Recent Years
When data centres were just used for data storage and retrieval, do you remember? These days, they are intricate ecosystems with cutting-edge technologies and creative management techniques. The transition from traditional settings to cloud-based solutions, edge computing, and even the use of AI and machine learning has been enormous. It's a totally different game!

Driving Data Center Efficiency
They are efficiently using energy and anticipating maintenance requirements. Furthermore, data centres are becoming increasingly networked due to the Internet of Things (IoT). Not to mention sustainable solutions—water cooling systems, wind energy, and solar panels are all in use, making green the new black in data centre technology.

FAQs 

In 2024, what constitutes a "state-of-the-art" data centre?
Consider management powered by AI, renewable energy sources, and strict security measures. 

What effects are remote work trends having on data centres?
For remote teams, it's all about cloud services and decentralised data storage, which makes data quick, safe, and accessible.
`;

const cybersecurityContent = `
By 2024, the internet has evolved from a back alley to a thriving city. It's huge, vibrant, and, regrettably, full with online scammers and digital pickpockets. But do not worry! This blog article serves as your reliable road map through this maze of online dangers. From the ins and outs of phishing schemes to the mysteries of quantum encryption, we're covering it all.

A Brave New World: Cybersecurity
Let's all start by discussing the latest and most exciting developments in the realm of cyberspace. Artificial intelligence (AI) is becoming more and more important in cybersecurity; it's no longer only for science fiction. Consider AI to be your virtual security dog, alerting you to questionable activity and barking when it detects it. However, as our technology advances, so do the cybercriminals. It's a high-stakes game of digital cat and mouse since they're also deploying AI. 

Another popular trend? encryption using quantum mechanics. Although it seems like something from a James Bond film, this is genuine and exists right now. This system guards private information from even the most skilled hackers, acting as a virtual Fort Knox.

Cyber Hygiene: It's Not Just About Passwords Anymore
When was the last time your digital fortress could be secured with just a strong password? Those are the distant memories. These days, multi-factor authentication is crucial (MFA). Consider MFA as your virtual gatekeeper, verifying identification before granting entry to your virtual hangout. 

However, there's more. Phishing is not only a pastime, so exercise caution. These cunning cyber frauds deceive you into giving over your valuable data, much like the Trojan horse of the digital age. People, always verify emails, even if they appear to be authentic.

Buckle Up: Corporate Cybersecurity in the Modern Age
In 2024, if you are a business owner, cybersecurity needs to be your first priority. Safeguarding your reputation is equally as important as securing your data. Your first line of defence is a team that is knowledgeable. Help them recognise the warning indicators of a cyberattack. Staff training is also essential. Keep in mind that your staff may be your greatest ally or your worst adversary.

Even the governments are becoming more proactive in this high-tech era. Cybersecurity laws and regulations serve as a kind of internet traffic laws, regulating digital malfeasance and data breaches. It is imperative that businesses be informed about these policies in order to prevent expensive penalties and negative publicity.

Conclusion: Cyber Safety is a Journey, Not a Destination
Ultimately, 2024 cybersecurity will revolve around remaining aware, knowledgeable, and flexible. There are curves at every bend on this never-ending trip. But, you may go through this cyber jungle like an expert if you have the correct information and resources. Remain alert, keep studying, and keep in mind that the greatest defence in the digital age is a strong offence. 

That sounds like a quick overview of the cybersecurity industry in 2024. Please inquire if you need any more information or if you have any more questions!
`;

const WhatWeAreThink = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="pt-24">
      <FrontPageHero frontHeading="What we think ?" height="30" />
      <div className="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
        <div className="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-2">
          <WhatWeDoCard
            heading2="The Digital Backbone: Mastering Data Center Services"
            para={dataCenterContent}
            image={devImage}
          />
          <WhatWeDoCard
            heading2="TNavigating the Cyber Jungle: Essential Cybersecurity Trends for 2024
            "
            para={cybersecurityContent}
            image={cloudImage}
          />
        </div>
      </div>
    </div>
  );
};

export default WhatWeAreThink;
