import React from "react";
import Heading2 from "./Heading2";
import Para from "./Para";
import videoSrc from "../assets/vid.mp4";
import { Link } from "react-router-dom";

const FrontPage3 = ({ heading, description, image }) => {
  return (
    <div className="">
      <div className="container mx-auto py-12 px-6 md:px-36">
        <div className="flex flex-col md:flex-row md:justify-center items-center">
          <div
            className="md:w-1/2 lg:w-1/2"
            data-aos="fade-right"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-offset="-200"
          >
            <Heading2 heading2={heading} />
            <Para para={description} />
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="Contact_us"
                className=" bg-[#1151d2] px-3.5 py-2.5 text-sm font-display font-semibold text-white shadow-sm "
              >
                {" "}
                Get Started Today
              </Link>
              <Link
                to="What_we_do"
                className="text-sm font-display font-semibold leading-6 text-black"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div
            className="md:w-1/2 lg:w-1/2 md:mt-0 sm:p-10 pt-4"
            data-aos="fade-left"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-offset="0"
          >
            <video
              src={videoSrc}
              autoPlay
              loop
              muted
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontPage3;
