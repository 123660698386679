import React from "react";
import FrontPage3 from "./FrontPage3";
import TOP from "../assets/Hero-1.png";
import WhatWeDoCardsGroup from "./WhatWeDoCardsGroup";

const WhatWeDo = () => {
  return (
    <div className="pt-24">
      <FrontPage3
        description="We specialize in comprehensive IT solutions including Data Center management, advanced Networking services, flexible Cloud Services, cutting-edge Web & Software Development, and strategic IT Consultancy. Our team is dedicated to providing top-tier, scalable, and secure technological solutions tailored to your business needs. Explore our offerings and let us empower your business with the latest in IT innovation."
        heading="Together, we can reinvent your business
        "
        image={TOP}
      />
      <WhatWeDoCardsGroup/>
    </div>
  );
};

export default WhatWeDo;
