import React from "react";
import Heading from "./Heading";

const FrontPageHero = ({ frontHeading, height = "50vh" }) => {
  return (
    <section className="bg-bg-secondary ">
      <div className={`mx-auto max-w-screen-xl px-6 pt-10  flex items-center h-[${height}] sm:py-8 lg:items-center`}>
        <div className="mx-auto  md:w-[90%] text-center">
          <Heading heading={frontHeading} textWidth="70"/>
        </div>
      </div>
    </section>
  );
};

export default FrontPageHero;
