import React from "react";
import ServiceCard from "./ServiceCard";
import {
  Cloud,
  Data,
  Cyber,
  Networking,
  Development,
  Consultancy,
} from "../assets/svgs/ServiceSvgs";
import Heading from "./Heading";
import Para from "./Para";

const Services = () => {
  return (
    <section className="py-12 px-4 bg-bg-primary sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center sm:w-[70%] sm:mx-auto">
          <Heading
            textAlign="center"
            heading="Together, we can reinvent your business"
          />
          <div className="pt-2 md:pt-10">
            <Para para="We specialize in comprehensive IT solutions including Data Center management, advanced Networking services, flexible Cloud Services, cutting-edge Web & Software Development, and strategic IT Consultancy. Our team is dedicated to providing top-tier, scalable, and secure technological solutions tailored to your business needs. Explore our offerings and let us empower your business with the latest in IT innovation." />
          </div>
        </div>

        <div className="grid grid-cols-1 pt-10  text-center  sm:grid-cols-2 sm:gap-x-12 gap-y-2 md:grid-cols-3 md:gap-0 md:mt-12">
          <div className="md:p-2 lg:p-1 ">
            <ServiceCard
              service="Data Center"
              description="High-density, secure data centers with top-notch connectivity and support."
              serviceSvg={<Data />}
            />
          </div>

          <div className="md:p-2 lg:p-2 ">
            <ServiceCard
              service="Cloud Services

              "
              description="Flexible, scalable cloud solutions for development, deployment, and subscription-based services."
              serviceSvg={<Cloud />}
            />
          </div>
          <div className="md:p-2 lg:p-2">
            <ServiceCard
              service="Cyber Security"
              description="Building Cybersecurity Resilience: Key Concepts and Strategies"
              serviceSvg={<Cyber />}
            />
          </div>

          <div className="md:p-2 lg:p-2">
            <ServiceCard
              service="Web & Software Development

              "
              description="Empowering Organizations through Web and Software Development"
              serviceSvg={<Development />}
            />
          </div>

          <div className="md:p-2 lg:p-2">
            <ServiceCard
              service="Networking"
              description="Scalable Networking Solutions for Seamless Communication"
              serviceSvg={<Networking />}
            />
          </div>

          <div className="md:p-2 lg:p-2">
            <ServiceCard
              service="IT Consultancy

              "
              description="Strategic IT Consulting: Aligning Technology with Business Goals"
              serviceSvg={<Consultancy />}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
