import React from "react";

const Heading2 = ({ heading2 }) => {
  return (
    <h1
      data-aos="zoom-in"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
      data-aos-offset="-200"
      className="text-2xl text-black font-display font-bold mb-6"
    >
      {heading2}
      <br className="hidden md:block" />
    </h1>
  );
};

export default Heading2;
