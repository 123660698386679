import { useEffect } from "react"
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hero from "./Components/Hero";
import WhatWeDo from "./Components/WhatWeDo";
import WhatWeAreThink from "./Components/WhatWeAreThink";
import WhoWeAre from "./Components/WhoWeAre";
import Contanc from "./Components/Contanc";


export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);

 
  return (
    <BrowserRouter>
      <div className=" overflow-x-hidden">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={<Hero />}
          ></Route>
          <Route path="What_we_do" element={< WhatWeDo />} />
          <Route path="What_we_think" element={< WhatWeAreThink />} />
          <Route path="Who_we_are" element={< WhoWeAre />} />
          <Route path="Contact_us" element={< Contanc />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  )
}