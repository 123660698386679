import React from "react";
import Heading2 from "./Heading2";
import Para from "./Para";

const WhatWeDoCard = ({ image, heading2, para }) => {
  return (
    <>
      <div className="p-6">
        <img
          className="object-cover object-center w-full mb-8 lg:h-48 md:h-36 rounded-xl"
          src={image}
          alt="blog"
        />
        <Heading2 heading2={heading2} />
        <Para para={para} />
        <p className="mx-auto text-base leading-relaxed text-gray-500"></p>{" "}
        {/* <div className="mt-4">
          <a
            href="#"
            className="inline-flex items-center mt-4 font-display font-semibold pb-4 text-black bg-clip-text   "
            title="read more"
          >
            {" "}
            Read More »{" "}
          </a>
        </div> */}
      </div>
    </>
  );
};

export default WhatWeDoCard;
