import React from "react";

const Heading = ({ heading, textAlign, textWidth = "100" }) => {
  const dynamicClassName = `pb-4 text-3xl sm:text-4xl  font-display font-display font-extrabold text-[#1151d2] sm:mx-auto sm:w-[${textWidth}%] text-${
    textAlign || "left"
  } sm:text-center`;

  return (
    <h1
      data-aos="zoom-in-down"
      data-aos-delay="0"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      /* className="pb-4 text-black bg-clip-text text-3xl font-display font-extrabold   sm:text-6xl text-left sm:text-center" */
      className={dynamicClassName}
    >
      {heading}
    </h1>
  );
};

export default Heading;
