import React from "react";
import FrontPageHero from "./FrontPageHero";
import FrontPage3 from "./FrontPage3";
import FrontPage2 from "./FrontPage2";
import Services from "./Services";
import Page4 from "./Page4";
import TOP from "../assets/Hero-1.png";

const Hero = () => {
  return (
    <div>
      <div className="pt-24 pb-4   bg-bg-secondary">
        <FrontPageHero
          frontHeading="Revolutionizing the Future: Where Technology Meets Tomorrow's
        Dreams."
          height="30"
        />
      </div>
      {/* <FrontPage4 /> */}
      <FrontPage3
        image={TOP}
        description="To promote success and expansion, we provide an extensive array of
              IT services. Our team of professionals has the knowledge and
              experience to handle any technological difficulty, from network
              infrastructure and cybersecurity to web development and software
              solutions. We provide the tools and resources to support
              businesses of all sizes in achieving their objectives."
        heading="              Empowering Innovation, Bridging Dreams and Reality with
              Cutting-Edge Technology"
      />
      <FrontPage2
        image={TOP}
        description="
        In the realm of communication, technology has shattered geographical barriers, enabling instant connections across the globe. Smartphones, social media, and video conferencing have not only changed how we interact but have also redefined the very essence of community and connectivity.
        
        Healthcare has seen monumental advances thanks to technology. Medical devices, from MRI machines to wearable fitness trackers, have enhanced our ability to diagnose and treat diseases. Telemedicine and AI-driven diagnostics are pushing the boundaries of personalized care."
        heading="Revolutionizing Connectivity and Technology's Impact on Society"
      />
      <Services />
      <Page4 />
    </div>
  );
};

export default Hero;
