import React from "react";
import Heading2 from "./Heading2";
import Para from "./Para";
import Hero from "../assets/top.jpg";

const FrontPage2 = ({ heading, description, image }) => {
  return (
    <div className="bg-bg-secondary ">
      <div className="container mx-auto py-12 px-6 md:px-36">
        <div className="flex flex-col md:flex-row md:justify-center items-center">
          <div
            className="md:w-1/2 lg:w-1/2 mt-8 sm:pe-10 pb-6"
            data-aos="fade-left"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-offset="0"
          >
            <img src={Hero} className="w-full h-auto" />
          </div>
          <div
            className="md:w-1/2 lg:w-1/2"
            data-aos="fade-right"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-offset="-200"
          >
            <Heading2 heading2={heading} />
            <Para para={description} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontPage2;
