import React from "react";

const Button = ({ btn }) => {
  return (
    <button className=" flex items-center gap-x-6">
      <a
        href="#"
        className="rounded-md bg-[#143aa2] px-3.5 py-2.5 text-sm font-display font-semibold text-[#d3d4d7] shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
      >
        {btn}
      </a>
    </button>
  );
};

export default Button;
