import React from "react";

const Para = ({ para, serviceCardText }) => {
  const serviceCardP =
    "text-sm mt-5 font-display font-medium text-gray-800  m-auto w-auto";
  const dynamicClassName = "text-sm font-display font-semibold text-gray-800";

  return (
    <p
      data-aos="zoom-in-down"
      data-aos-delay="0"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      className={serviceCardText ? serviceCardP : dynamicClassName}
    >
      {para}
    </p>
  );
};

export default Para;
