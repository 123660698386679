import React from "react";
import FrontPageHero from "./FrontPageHero";
import Button from "./Button";
import Para from "./Para";
import Heading2 from "./Heading2";

const Contanc = () => {
  return (
    <div className="mt-36">
      <div class="my-6">
        <div class="grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-display">
          <div>
            <h1
              data-aos="zoom-in-down"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              className="pb-4 text-3xl sm:text-4xl font-display font-extrabold text-[#1151d2] sm:mx-auto"
            >
              Say Hello
            </h1>
            <p
              class="text-sm text-font-secondary-color mt-3"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-offset="-200"
            >
              Please contact us for any inquiries or comments; your input is
              valuable to us and we look forward to hearing from you.
            </p>
            <div
              class="mt-12"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-offset="-200"
            >
              <h2 class="text-lg font-extrabold">Email</h2>
              <ul class="">
                <li class="flex items-center">
                  <a
                    href="mailto:nikunj.26@vishagtech.in"
                    class="text-[#007bff] text-sm"
                  >
                    <strong>nikunj.26@vishagtech.in</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="mt-4"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-offset="-200"
            >
              <h2 class="text-lg font-extrabold">Phone</h2>
              <ul class="">
                <li class="flex items-center">
                  <a href="tel:+91-6351447152" class="text-[#007bff] text-sm">
                    <strong>+91-6351447152</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <form
            class="ml-auo space-y-4"
            data-aos="fade-left"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-offset="0"
          >
            <input
              type="text"
              placeholder="Name"
              class="w-full rounded-md py-2.5 px-4 border text-sm border-blue-300 outline-[#007bff]"
            />
            <input
              type="email"
              placeholder="Email"
              class="w-full rounded-md py-2.5 px-4 border  text-sm border-blue-300 outline-[#007bff]"
            />
            <input
              type="text"
              placeholder="Subject"
              class="w-full rounded-md py-2.5 px-4 border text-sm border-blue-300 outline-[#007bff]"
            />
            <textarea
              placeholder="Message"
              rows="6"
              class="w-full rounded-md px-4 border text-sm pt-2.5 border-blue-300 outline-[#007bff]"
            ></textarea>
            <button
              type="button"
              class="text-white bg-[#007bff] hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full"
            >
              Send
            </button>
          </form>
        </div>
      </div>

      <div class="my-6">
        <div class="grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-display">
          <div>
            <h1
              data-aos="zoom-in-down"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              className="pb-4 text-3xl sm:text-4xl   font-display font-extrabold text-[#1151d2] sm:mx-auto"
            >
              Visit Us
            </h1>
            <p
              class="text-sm text-font-secondary-color mt-3"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-offset="-200"
            >
              Please feel free to contact us with any inquiries or feedback.
              Your communication is important to us. Kindly use the form below
              to send your message, and we will respond promptly.
            </p>
            <div
              class="mt-12"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-offset="-200"
            >
              <h2 class="text-lg font-extrabold">Address</h2>
              <ul class="">
                <li class="flex items-center">
                  <a target="blank" href="#" class="text-[#007bff] text-sm">
                    <strong>
                      A-3 Shree Rang Yogi Park Soc., <br /> Near Shrenik Park
                      Cross Road, Akota,
                      <br /> Vadodara - 390020
                    </strong>
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="mt-4"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-offset="-200"
            >
              <h2 class="text-lg font-extrabold">Opening Hours</h2>
              <ul class="">
                <li class="flex items-center">
                  <a target="blank" href="#" class="text-[#007bff] text-sm">
                    <strong>Mon - Fri : 9:00am – 6:00pm</strong>
                    <br />
                    <strong>Saturday : 9:00am – 3:00pm</strong>
                    <br />
                    <strong>​Sunday : Closed</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <iframe
              // width="100%"
              // height="100%"
              // className="absolute inset-0"
              className="h-[60vh] w-[80vw] md:w-[25vw]"
              data-aos="zoom-in-down"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              frameborder="0"
              title="map"
              marginheight="0"
              marginwidth="0"
              scrolling="no"
              src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=A-3%20Shree%20Rang%20Yogi%20Park%20Soc.,%20Near%20Shrenik%20Park%20Cross%20Road,%20Akota,%20Vadodara%20-%20390020&ie=UTF8&t=&z=14&iwloc=B&output=embed
              "
              style={{ filter: "grayscale0(0.5) contrast(1.2) opacity(0.4)" }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contanc;
