import React from "react";
import Para from "./Para";

const ServiceCard = ({ service, description, serviceSvg }) => {
  return (
    <div
      className="bg-bg-secondary h-[20rem] p-6 md:p-10 transition-all duration-300 hover:scale-110 shadow-lg"
      data-aos="zoom-in-down"
      data-aos-delay="0"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      {serviceSvg}
      <h3 className="text-black bg-clip-text text-lg font-display font-extrabold   sm:text-xl mt-6 md:mt-12 ">
        {service}
      </h3>

      <Para serviceCardText="true" para={description} />
    </div>
  );
};

export default ServiceCard;
