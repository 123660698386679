import React from "react";
import gif from "../assets/cartoon-480_256.gif";
import Heading2 from "./Heading2";
import Para from "./Para";
import { Link } from "react-router-dom";

const Page4 = () => {
  return (
    <div className="relative isolate overflow-hidden bg-bg-secondary">
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-10">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
          <div className="mt-24 sm:mt-32 lg:mt-16"></div>
          <Heading2 heading2="Advance Your Company with Cutting-Edge IT Solutions" />
          <Para
            para=" Are you prepared to use cutting-edge IT solutions to advance your
            company? To arrange a consultation and learn how VISHAG may assist
            you in realising your objectives, get in touch with us right now."
          />
          <div className="mt-10 flex items-center gap-x-6">
            <Link
              to="Contact_us"
              className=" bg-[#1151d2] px-3.5 py-2.5 text-sm font-display font-semibold text-white shadow-sm "
            >
              {" "}
              Get Started Today
            </Link>
            <Link
              to="What_we_do"
              className="text-sm font-display font-semibold leading-6 text-black"
            >
              Learn more <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
        <div className="mx-auto mt-16 flex w-[60%] over sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-[60%] lg:flex-none xl:ml-32">
          <img src={gif} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Page4;
