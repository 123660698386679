import React from "react";
import FrontPageHero from "./FrontPageHero";
import Para from "./Para";
import Heading2 from "./Heading2";

const WhoWeAre = () => {
  return (
    <div className="mt-24">
      <FrontPageHero frontHeading="Who we are ?" height="30" />
      <div className="m-auto w-auto pt-6 text-center sm:w-1/2">
        <Para para="Our expertly crafted services offer cutting-edge data center technologies, innovative networking solutions, and versatile cloud services, designed for the modern business. Dive into our world of advanced web and software development, where imagination meets functionality. Experience the pinnacle of strategic IT consultancy, where we transform challenges into victories. Join us in redefining excellence – your gateway to unparalleled IT expertise starts here!" />

        <div className="mt-10 p-4 border-b-[1px] border-b-gray-600">
          <Heading2 heading2="Nikunj B. Rajput" />
          <div className="mb-4">
            <Para para="Founder & CEO" />
          </div>
          <Para para="My journey starts from a passionate student, deeply interested in technology and it’s application. I begin my career in networking and cyber security, where my skills are honed in designing and implementing high performance network infrastructures. Also got my hands trained to be skilled for security breaches and tried them on some freelancing projects. After all getting a deep understanding of networking and security, in the meantime I was ready to shake hand with one of my friend to begin with startup and there is was been appointed. Each step on this journey was a building block towards a greater goal and they were the foundational skills that would empower a future Managing Director.​​" />
        </div>
        <div className="mt-10 p-4 border-b-[1px] border-b-gray-600">
          <Heading2 heading2="Zeal A. Patel" />
          <div className="mb-4">
            <Para para="Managing Director" />
          </div>
          <Para para="Growing up with a keen interest in technology and innovation. In meantime I pursued my Master’s degree in computer science from Illinois Institute of Technology, Chicago. Where the passion of technology and business began to merge. During these years in university, I didn’t only excelled in academics but also actively participated in entrepreneurial clubs, absorbing every bit of knowledge about starting and running a business. While still in the university I started my first venture it was small software development firm that took on freelance projects on which we gradually scaled up and tried to make a medium scale business. The journey was not smooth, from securing funding to building a reliable team. We are continuing to innovate and inspire showing that with passion and perseverance, any student can become a change making entrepreneur." />
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
