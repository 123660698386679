import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../assets/black-logo.png";
import { Link } from "react-router-dom";
import Button from "./Button";

const Navbar = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  const handleNavFromLogo = () => {
    setNav(false);
  };

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  // Array containing navigation items
  const navItems = [
    { id: 1, text: "What we do ?", link: "What_we_do" },
    { id: 2, text: "What we think ?", link: "What_we_think" },
    { id: 3, text: "Who we are ?", link: "Who_we_are" },
    // { id: 4, text: "Contact us", link: "Contact_us" },
  ];

  return (
    <div
      className="z-[1000] fixed w-[100vw] top-0 pt-4 bg-bg-primary
    flex justify-between items-center h-24  mx-auto px-2 md:px-6 text-black sm:px-10 border-black shadow-xl"
    >
      {/* Logo */}
      {/* <h1 className=" text-3xl font-display font-bold text-[#00df9a]"></h1> */}
      <div
        data-aos="fade-right"
        data-aos-delay="0"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-offset="-200"
      >
        <Link
          className="flex justify-between items-center m-auto pb-4"
          onClick={() => {
            handleNavFromLogo(false);
            handleScroll();
          }}
          to="/"
        >
          <img className="w-24 " src={logo} alt="" />
          <p className="flex flex-col font-display font-bold transition-all text-3xl hover:scale-110 hover:border-b border-b-gray-300">
            VISHAG
            <span className="ps-1 text-sm">TECH PVT LTD</span>
          </p>
        </Link>
      </div>

      {/* Desktop Navigation */}
      <ul className="hidden md:flex ">
        {navItems.map((item) => (
          <li
            key={item.id}
            onClick={handleScroll}
            className="mx-4  m-2 cursor-pointer duration-300 text-black hover:text-blue-300 bg-clip-text text-md  font-display font-bold transition-all  hover:scale-110 hover:border-b border-b-gray-300"
          >
            <Link
              data-aos="fade-left"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-offset="-200"
              to={`/${item.link}`}
              className="relative inline-flex items-center justify-center px-2 py-1  overflow-hidden font-display font-bold text-black "
            >
              <span className="relative ">{item.text}</span>
            </Link>
          </li>
        ))}
        <li
          onClick={handleScroll}
          className="mx-4  m-2 cursor-pointer duration-300 text-black hover:text-blue-300 bg-clip-text text-md  font-display font-bold transition-all  hover:scale-110 hover:border-b border-b-gray-300"
        >
          <Link
            data-aos="fade-left"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-offset="-200"
            to="Contact_us"
            className="relative inline-flex items-center justify-center px-2 py-1  overflow-hidden font-display font-bold text-black border-2 text-white bg-[#1151d2] border-[#1151d2] "
          >
            <span className="relative ">Contact Us</span>
          </Link>
        </li>
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? "z-10 fixed md:hidden left-0 top-[6rem] w-[100%] h-[100vh] border-r border-r-gray-900 bg-bg-secondary ease-in-out duration-500 pt-4"
            : "ease-in-out w-[60%] duration-500 fixed top-10 bottom-0 left-[-100%]"
        }
      >
        {/* Mobile Navigation Items */}
        {navItems.map((item) => (
          <li
            key={item.id}
            className="p-4 ps-10 rounded-xl text-black/85  cursor-pointer "
            onClick={() => {
              handleNav();
              handleScroll();
            }}
          >
            <Link
              className=" hover:text-blue-300 bg-clip-text text-md  font-display font-bold transition-all  hover:scale-110 hover:border-b border-b-gray-300"
              to={`/${item.link}`}
            >
              {item.text}
            </Link>
          </li>
        ))}
        <li
          className="p-4 ps-10 rounded-xl text-black/85  cursor-pointer "
          onClick={() => {
            handleNav();
            handleScroll();
          }}
        >
          <Link
            className=" hover:text-blue-300 bg-clip-text text-md  font-display font-bold transition-all  hover:scale-110 hover:border-b border-b-gray-300"
            to="Contact_us"
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
